@font-face {
  font-family: 'Roboto-Black';
  src: url('../../_assets/fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../../_assets/fonts/Roboto-BlackItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../_assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../../_assets/fonts/Roboto-BoldItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('../../_assets/fonts/Roboto-Italic.ttf');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../../_assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../../_assets/fonts/Roboto-LightItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../_assets/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../../_assets/fonts/Roboto-MediumItalic.ttf');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../_assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('../../_assets/fonts/Roboto-Thin.ttf');
}
@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../../_assets/fonts/Roboto-ThinItalic.ttf');
}
